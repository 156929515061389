import styled from "styled-components"

const SearchBox = styled.input`
  ${({ theme }) => `
    outline: 0;
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.rg};
    border: 0;
    box-shadow: none;
  `}
`

export default SearchBox
