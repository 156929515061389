import { useEffect, useRef, useState } from "react"

const usePreloadImages = sources => {
  const [loading, setLoading] = useState(true)
  const imagesLeft = useRef(sources.length)

  useEffect(() => {
    sources.forEach(src => {
      const image = new Image()
      image.onload = () => {
        imagesLeft.current = imagesLeft.current - 1
        if (imagesLeft.current === 0) {
          setLoading(false)
        }
      }
      image.src = src
    })
  }, [sources])

  return loading
}

export default usePreloadImages
