import React, { useRef } from "react"
import styled, { keyframes } from "styled-components"
import ReactDOM from "react-dom"
import useOutsideClick from "../hooks/useClickOutside"

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 100 };
`

const Container = styled.div`
  align-items: center;
  animation: ${fadeIn} 0.3s;
  background-color: rgba(22, 22, 22, 0.85);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 101;
`

const ModalContainer = styled.div`
  ${({ theme }) => `
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${theme.spaces[0]};
    position: relative;
  `}
`

const Modal = ({ className, children, onClose }) => {
  const ref = useRef()
  useOutsideClick(ref, onClose)

  const clickStopPropagation = e => {
    e.stopPropagation()
  }

  return ReactDOM.createPortal(
    <Container onClick={clickStopPropagation}>
      <ModalContainer ref={ref} className={className}>
        {children}
      </ModalContainer>
    </Container>,
    document.body
  )
}

export default Modal
