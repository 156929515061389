import styled from "styled-components"

const Button = styled.button`
  ${({ theme }) => `
    background-color: ${theme.colors.blue};
    color: white;
    display: inline-block;
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.rg};
    padding: 5px 20px;
    outline: 0;
    text-decoration: none;
    border: 0;
    box-shadow: none;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }
  `}
`

export default Button
