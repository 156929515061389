import React, { useEffect } from "react"
import Modal from "./Modal"
import styled from "styled-components"

const Container = styled.div`
  ${({ theme }) => `
    display: flex;
    height: 28rem;
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.rg};
    border-radius: 10px;
  `}
`

const Section = styled.div`
  ${({ theme }) => `
    display: none;
    height: inherit;
    overflow-y: hidden;
    width: 20rem;

    @media (min-width: ${theme.breakpoints[3]}) {
      display: block;
    }
  `}
`

const DetailSection = styled(Section)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 28rem;
  background-color: #e2e2e2;
  height: calc(100% - 2rem);
  padding: 1rem;
  overflow-y: scroll;
`

const NameText = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.blue};
    font-family: inherit;
    font-size: ${theme.fontSize.lg};
    font-weight: bold;
  `}
`

const LastNameText = styled(NameText)`
  text-transform: uppercase;
`

const BoldText = styled.div`
  font-family: inherit;
  font-weight: bold;
  font-size: inherit;
`

const BoldSpan = styled.span`
  font-family: inherit;
  font-weight: bold;
  font-size: inherit;
`

const SchoolText = styled(BoldText)`
  font-weight: normal;
`

const WriteUpParagraph = styled.p`
  white-space: pre-wrap;
  text-align: justify;
`

const SectionLabel = styled.div`
  ${({ theme }) => `
    font-family: inherit;
    font-weight: bold;
    font-size: inherit;
    color: ${theme.colors.blue};
  `}
`

const SubSection = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spaces[1]};
  `}
`

const Awards = styled.div`
  font-style: italic;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const GradDegree = styled(BoldText)`
  ${({ theme }) => `
    font-size: ${theme.fontSize.md};
  `}
`

const GraduateModal = ({ toggleModal, image, data }) => {
  useEffect(() => {
    document.body.style = "overflow-y: hidden;"

    return () => {
      document.body.style = "overflow-y: scroll;"
    }
  }, [])

  return (
    <Modal onClose={toggleModal}>
      <Container>
        <Section>
          <Image src={image} />
        </Section>
        <DetailSection>
          <div>
            <LastNameText>{data.lastName}</LastNameText>
            <NameText>{`, ${data.name}`}</NameText>
          </div>
          <SubSection>
            <GradDegree>{data.gradDegree}</GradDegree>
            {data.gradAwards && <Awards>{data.gradAwards.join(", ")}</Awards>}
          </SubSection>
          {data.writeUp && (
            <SubSection>
              <WriteUpParagraph>
                {data.writeUp.replaceAll("\n\n", "\n").replaceAll("\n", "\n\n")}
              </WriteUpParagraph>
            </SubSection>
          )}
          <SubSection>
            <SectionLabel>Undergraduate</SectionLabel>
            <BoldText>{data.undergradCourse}</BoldText>
            <SchoolText>{`${data.undergradSchool}, Yr. ${data.graduationYear}`}</SchoolText>
            {data.undergradAwards && (
              <Awards>{data.undergradAwards.join(", ")}</Awards>
            )}
          </SubSection>
          {data.affiliations && (
            <div>
              <SectionLabel>Affiliations</SectionLabel>
              {data.affiliations.map((aff, i) => (
                <div key={i}>
                  <BoldSpan>{`${aff.position} - `}</BoldSpan>
                  <span>{`${aff.affiliation} ${
                    aff.year ? `, (${aff.year})` : ""
                  }`}</span>
                </div>
              ))}
            </div>
          )}
        </DetailSection>
      </Container>
    </Modal>
  )
}

export default GraduateModal
