import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import usePreloadImages from "../hooks/usePreloadImages"

const Container = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.blue};
    height: 35rem;
    min-height: 420px;
    position: relative;
    width: 100%;
  `}
`

const HeroProper = styled(motion.div)`
  ${({ theme, backgroundImage }) => `
    background-attachment: fixed;
    background-image: url("${backgroundImage}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
    min-height: inherit;
    opacity: 0;
    padding-left: ${theme.spaces[0]};
    padding-right: ${theme.spaces[0]};
    width: calc(100% - ${theme.spaces[0]} * 2);
  `}
`

const heroVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const Hero = ({ children, preloadImages, backgroundImage }) => {
  const isLoading = usePreloadImages(preloadImages)

  return (
    <Container>
      <HeroProper
        animate={isLoading ? "hidden" : "visible"}
        variants={heroVariants}
        backgroundImage={backgroundImage}
      >
        {!isLoading && children}
      </HeroProper>
    </Container>
  )
}

export default Hero
