import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import UPCMClassFormalPhoto from "../images/class-photo-formal.jpg"
import Hero from "../components/Hero"
import MarginContainer from "../components/MarginContainer"
import FoldContainer from "../components/FoldContainer"
import { graphql } from "gatsby"
import SearchBox from "../components/SearchBox"
import Button from "../components/Button"

import GraduateModal from "../components/GraduateModal"
import classList from "../yearbook.json"
import SuspenseImage from "../components/SuspenseImage"
import Skeleton from "react-loading-skeleton"
import { X } from "react-feather"

const BlackOverlay = styled.div`
  background-color: black;
  height: inherit;
  opacity: 0.6;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

const HeroContentContainer = styled.div`
  ${({ theme }) => `
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    font-family: ${theme.fonts.proxima};
    justify-content: center;
    overflow: hidden;
    padding-top: 15rem;
    width: inherit;
    margin: auto;
  `}
`

const Title = styled.span`
  ${({ theme }) => `
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.md};
    color: white;
    text-transform: uppercase;
    z-index: 1;
  `}
`

const ClassText = styled.span`
  ${({ theme }) => `
    font-family: ${theme.fonts.andellia};
    font-size: 5rem;
    color: white;
    z-index: 1;

    @media (min-width: ${theme.breakpoints[2]}){
      font-size: 10rem;
    }
  `}
`

const SearchContainer = styled.div`
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`

const GalleryContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  margin-bottom: 1rem;
`

const GalleryBox = styled.div`
  ${({ theme }) => `
    display: block;
    text-align: center;
    font-size: ${theme.fontSize.lg};
    font-family: ${theme.fonts.proxima};

    &:hover {
      cursor: pointer;
    }

    @media (min-width: ${theme.breakpoints[1]}){
      font-size: ${theme.fontSize.rg};
    }
  `}
`

const GalleryImage = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 35rem;
    margin-bottom: 10px;

    @media (min-width: ${theme.breakpoints[1]}){
      height: 35rem;
    }

    @media (min-width: ${theme.breakpoints[2]}){
      height: 25rem;
    }
  `}
`

const NoResults = styled.div`
  ${({ theme }) => `
    width: 100%;
    text-align: center;
    height: 20rem;
    font-family: ${theme.fonts.proxima};
    margin-top: 20px;
  `}
`

const ClearButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
`

const SearchBoxContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid black;
  margin-right: 10px;
  width: calc(12rem + 15px);
  height: calc(1rem + 10px);
  padding: 5px 5px 5px 10px;
  & > input {
    width: 11rem;
  }
`

const Class2021Page = () => {
  const preloadImages = [UPCMClassFormalPhoto]
  const [clickedGraduate, setClickedGraduate] = useState(null)
  const [searchString, setSearchString] = useState("")
  const searchRef = useRef()

  useEffect(() => {
    document.addEventListener("keyup", event => {
      if (event.key === "Enter") {
        event.preventDefault()
        setSearchString(searchRef.current.value)
      }
    })
  }, [])

  const closeModal = () => {
    setClickedGraduate(null)
  }

  const clearSearch = () => {
    setSearchString("")
    searchRef.current.value = ""
  }

  const currentGraduate = clickedGraduate
    ? classList.find(cl => cl.picture === clickedGraduate)
    : null

  const onSearch = () => {
    setSearchString(searchRef.current.value)
  }

  const filteredClassList = searchString
    ? classList.filter(cl => cl.fullName.includes(searchString.toLowerCase()))
    : classList

  return (
    <>
      <Hero
        preloadImages={preloadImages}
        backgroundImage={UPCMClassFormalPhoto}
      >
        <HeroContentContainer>
          <Title>UP College of Medicine</Title>
          <ClassText>Class of 2021</ClassText>
        </HeroContentContainer>
        <BlackOverlay />
      </Hero>
      <FoldContainer>
        <MarginContainer>
          <SearchContainer>
            <SearchBoxContainer>
              <SearchBox placeholder="Enter name of graduate" ref={searchRef} />
              {searchString && (
                <ClearButton onClick={clearSearch}>
                  <X color="red" />
                </ClearButton>
              )}
            </SearchBoxContainer>
            <Button onClick={onSearch}>Search</Button>
          </SearchContainer>
          <GalleryContainer>
            {filteredClassList.map(cl => (
              <GalleryBox
                onClick={() => setClickedGraduate(cl.picture)}
                key={cl.picture}
              >
                <GalleryImage>
                  <SuspenseImage
                    src={`/yearbook/${cl.picture}`}
                    fallback={<Skeleton height="100%" />}
                  />
                </GalleryImage>
                {`${cl.lastName}, ${cl.name}`}
              </GalleryBox>
            ))}
          </GalleryContainer>
          {filteredClassList.length === 0 && (
            <NoResults>No results found.</NoResults>
          )}
        </MarginContainer>
      </FoldContainer>
      {!!currentGraduate && (
        <GraduateModal
          image={`/yearbook/${currentGraduate.picture}`}
          toggleModal={closeModal}
          data={currentGraduate}
        />
      )}
    </>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "class-photo-preview.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

export default Class2021Page
