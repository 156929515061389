import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import styled, { keyframes } from "styled-components"
import usePreloadImage from "../hooks/usePreloadImage"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const FullWidthImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: ${fadeIn} 0.5s ease-in;
`

const SuspenseImage = ({ fallback, src }) => {
  const hasLoaded = usePreloadImage(src)

  return (
    <VisibilitySensor>
      {hasLoaded ? <FullWidthImage src={src} /> : fallback}
    </VisibilitySensor>
  )
}

export default SuspenseImage
